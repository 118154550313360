<script>
    import Teams from '@/services/Teams'
    import Swal from "sweetalert2";

    export default {
        props: {
            team_id: {
                type: String,
                default: null
            },
            ticketing_url: {
                type: String,
                default: null
            },
        },
        data() {
            return {
                showModal: false,
                tryingToEdit: false,
            };
        },
        methods: {
            refreshTeams() { 
                this.$emit('onRefresh') //event from parent
            },
            async editTeam() {
                this.tryingToEdit = true;

                await Teams.editTeams({
                    team_id: this.team_id, 
                    ticketing_url: this.ticketing_url
                })
                .then(() => {
                    this.successmsg();
                    this.closeModal();
                })
                .catch(error => {
                    //console.log(error.response.data.error);
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg( this.error )
                })
                .finally(() => {
                    this.refreshTeams()
                    this.tryingToEdit = false;
                })
            },

            closeModal() {
                this.showModal = false;
            },

            successmsg() {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Change has been saved",
                    showConfirmButton: false,
                    timer: 2000,
                });
            },

            failedmsg(msg) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: msg,
                    showConfirmButton: false,
                    timer: 5000,
                });
            },
        }
    };
</script>



<template>
    <b-modal  v-model="showModal" id="teams_edit" title="Edit Team" title-class="font-18" centered>
        <form @submit.prevent="editTeam">
            <b-form-group label="Ticketing URL" label-for="formrow-firstname-input" class="mb-3">
                <b-form-input v-model="ticketing_url" type="text"  ></b-form-input>
            </b-form-group>     
        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="editTeam" :disabled="tryingToEdit">Change</b-button>
        </template>
    </b-modal>
</template>